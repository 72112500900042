import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/IndexPage.vue') },
      {
        path: 'modine-employee',
        component: () => import('pages/ModineEmployeePage.vue'),
      },
      {
        path: 'plus',
        component: () => import('pages/PlusPage.vue'),
      },
      {
        path: 'careers',
        component: () => import('pages/CareersPage.vue'),
      },
      {
        path: 'gamecenter',
        component: () => import('pages/gamecenter/GameCenterPage.vue'),
      },
      {
        path: 'insix',
        component: () => import('pages/InSixPage.vue'),
      },
      {
        path: 'sustainability',
        component: () => import('pages/SustainabilityPage.vue'),
      },
      {
        path: 'test',
        component: () => import('pages/AdTestPage.vue'),
      },
      {
        path: 'community',
        component: () => import('pages/community/IndexPage.vue'),
      },
      {
        path: 'community/donation-redemption',
        component: () => import('pages/community/DonationRedemptionPage.vue'),
      },
      {
        path: 'community/education',
        component: () => import('pages/community/EducationPage.vue'),
      },
      {
        path: 'community/community-betterment',
        component: () => import('pages/community/CommunityBettermentPage.vue'),
      },
      {
        path: 'community/health-and-wellness',
        component: () => import('pages/community/HealthAndWellnessPage.vue'),
      },
      {
        path: 'photo-video-recording-or-other-materials-submitted-to-the-milwaukee-bucks-llc-2',
        component: () => import('pages/DisclaimerPage.vue'),
      },
      {
        path: 'sc-johnson-tips-on-household-recycling',
        component: () => import('../pages/scJohnsonPage.vue'),
      },
      {
        path: 'fans/all-star',
        component: () => import('pages/fans/AllStarPage.vue'),
      },
      {
        path: 'fans/azuna',
        component: () => import('pages/fans/AzunaPage.vue'),
      },
      {
        path: 'fans/junipero',
        component: () => import('pages/fans/JuniperoPage.vue'),
      },
      {
        path: 'fans/eleven-sports',
        component: () => import('pages/fans/ElevenSportsPage.vue'),
      },
      {
        path: 'fans/jackdaniels',
        component: () => import('pages/fans/JackDanielsPage.vue'),
      },
      {
        path: 'fans/414-all',
        component: () => import('pages/fans/414AllPage.vue'),
      },
      {
        path: 'fans/student-rush',
        component: () => import('pages/fans/StudentRushPage.vue'),
      },
      {
        path: 'fiserv-fastbreak-clover-helps-small-businesses',
        component: () => import('pages/FiservFastbreak.vue'),
      },
      {
        path: 'foundation',
        component: () => import('pages/foundation/FoundationPage.vue'),
      },
      {
        path: 'arena',
        component: () => import('pages/arena/ArenaPage.vue'),
      },
      {
        path: 'arena/seating-maps',
        component: () => import('pages/arena/SeatingMapsPage.vue'),
      },
      {
        path: 'tickets/milwaukee-bucks-season-membership-waitlist-terms-and-conditions',
        component: () => import('pages/tickets/MembershipTosPage.vue'),
      },
      {
        path: 'tickets/purchase-terms-and-conditions',
        component: () => import('pages/tickets/TosPage.vue'),
      },
      {
        path: 'sweeps/play-and-stay',
        component: () => import('pages/sweeps/PlayAndStayPage.vue'),
      },
      {
        path: 'sweeps/drafttrivia',
        component: () => import('pages/sweeps/DraftTriviaPage.vue'),
      },
      {
        path: 'sweeps/ultracourtside',
        component: () => import('pages/sweeps/UltraCourtsidePage.vue'),
      },
      {
        path: 'sweeps/jameson',
        component: () => import('pages/sweeps/JamesonSweepstakesPage.vue'),
      },
      {
        path: 'sweeps/sportscave',
        component: () => import('pages/sweeps/SportsCavePage.vue'),
      },
      {
        path: 'city/sweepstakes',
        component: () => import('pages/city/SweepstakesPage.vue'),
      },
    ],
  },
  {
    path: '/tickets',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/tickets/TicketsPage.vue'),
      },
      {
        path: 'premium',
        component: () => import('pages/tickets/PremiumPage.vue'),
      },
      {
        path: 'groups',
        component: () => import('pages/tickets/groups/GroupsPage.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('layouts/BoxedLayout.vue'),
    children: [
      {
        path: 'sixthmansoiree',
        component: () => import('pages/SixthManSoireePage.vue'),
        props: true,
      },
      {
        path: 'tickets/premium/:slug',
        component: () => import('pages/tickets/_slug.vue'),
        props: true,
      },
      {
        path: 'tickets/full',
        component: () => import('pages/tickets/FullPage.vue'),
      },
      {
        path: 'tickets/partial',
        component: () => import('pages/tickets/PartialPage.vue'),
      },
      {
        path: 'tickets/promos',
        component: () => import('pages/tickets/promos/IndexPage.vue'),
      },
      {
        path: 'tickets/groups/junior-bucks',
        component: () => import('pages/tickets/groups/JuniorBucksPage.vue'),
      },
      {
        path: 'tickets/groups/ticketpass',
        component: () => import('pages/tickets/groups/TicketPassPage.vue'),
      },
      {
        path: 'tickets/groups/fundraising',
        component: () => import('pages/tickets/groups/FundraisingPage.vue'),
      },
      {
        path: 'tickets/groups/fan-experiences',
        component: () => import('pages/tickets/groups/FanExperiencesPage.vue'),
      },
      {
        path: 'tickets/theres-a-seat-for-you',
        component: () => import('pages/tickets/SeatForYouPage.vue'),
      },
      {
        path: 'tickets/upgrade-your-experience',
        component: () => import('pages/tickets/UpgradePage.vue'),
      },
      {
        path: 'tickets/hbcunight',
        component: () => import('pages/tickets/HbcuNight.vue'),
      },
      {
        path: 'tickets/mbj',
        component: () => import('pages/tickets/MbjPage.vue'),
      },
      {
        path: 'tickets/presale',
        component: () => import('pages/tickets/PresalePage.vue'),
      },
      {
        path: 'tickets/superpass',
        component: () => import('pages/tickets/SuperPassPage.vue'),
      },
      {
        path: 'tickets/scrimmage',
        component: () => import('pages/tickets/ScrimmagePage.vue'),
      },
      {
        path: 'playoffs',
        component: () => import('pages/PlayoffsPage.vue'),
      },
      {
        path: 'enewsletter',
        component: () => import('pages/FastbreakEnewsletterPage.vue'),
      },
      {
        path: 'injury-report',
        component: () => import('pages/InjuryReportPage.vue'),
      },
      {
        path: 'uniforms',
        component: () => import('pages/UniformsPage.vue'),
      },
      {
        path: 'foundation/scoreboard-messages',
        component: () => import('pages/foundation/ScoreboardMessagesPage.vue'),
      },
      {
        path: 'sms',
        component: () => import('pages/SmsPage.vue'),
      },
      {
        path: 'fans/bmo-cap-series',
        component: () => import('pages/fans/BmoCapSeriesPage.vue'),
      },
      {
        path: 'fans/birthdayclub',
        component: () => import('pages/fans/BirthdayClubPage.vue'),
      },
      {
        path: 'fans/meetup',
        component: () => import('pages/fans/MeetUpPage.vue'),
      },
      {
        path: 'fans/noche',
        component: () => import('pages/fans/NochePage.vue'),
      },
      {
        path: 'fans/daou',
        component: () => import('pages/fans/DaouPage.vue'),
      },
      {
        path: 'fans/stmevents',
        component: () => import('pages/fans/STMEventsPage.vue'),
      },
      {
        path: 'fans/deerbango',
        component: () => import('pages/fans/DeerBangoPage.vue'),
      },
      {
        path: 'fans/dash',
        component: () => import('pages/fans/DashPage.vue'),
      },
      {
        path: 'fans/ultrakwik95',
        component: () => import('pages/fans/UltraKwik95Page.vue'),
      },
      {
        path: 'fans/tipoff',
        component: () => import('pages/fans/TipoffPage.vue'),
      },
      {
        path: 'fans/wingstop',
        component: () => import('pages/fans/WingstopPage.vue'),
      },
      {
        path: 'community/black-history-month',
        component: () => import('pages/community/BlackHistoryMonthPage.vue'),
      },
      {
        path: 'community/donation-requests',
        component: () => import('pages/community/DonationRequestsPage.vue'),
      },
      {
        path: 'community/5050-raffle',
        component: () => import('pages/community/5050RafflePage.vue'),
      },
      {
        path: 'community/math-hoops',
        component: () => import('pages/community/MathHoops.vue'),
      },
      {
        path: 'community/charitable-ticket-program',
        component: () => import('pages/community/CharitableTicketProgram.vue'),
      },
      {
        path: 'hackathon',
        component: () => import('pages/HackathonPage.vue'),
      },
      {
        path: 'identity',
        component: () => import('pages/IdentityPage.vue'),
      },
      {
        path: 'cup',
        component: () => import('pages/NBACupPage.vue'),
      },
      {
        path: 'fans/kids-starting-lineup-presented-by-delta-dental',
        component: () => import('pages/fans/KidsStartingLineupPage.vue'),
      },
      {
        path: 'pride',
        component: () => import('pages/PridePage.vue'),
      },
      {
        path: 'profile-center',
        component: () => import('pages/ProfileCenter.vue'),
      },
      {
        path: 'schedule/download',
        component: () => import('pages/schedule/DownloadPage.vue'),
      },
      {
        path: 'deercrossing',
        component: () => import('pages/DeerCrossingPage.vue'),
      },
      {
        path: 'watch',
        component: () => import('pages/WatchPage.vue'),
      },
      {
        path: 'city',
        component: () => import('pages/CityPage.vue'),
      },
    ],
  },
  {
    path: '/entertainment',
    component: () => import('layouts/EntertainmentLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/entertainment/EntertainmentPage.vue'),
      },
      {
        path: '414-crew',
        component: () => import('pages/entertainment/414CrewPage.vue'),
      },
      {
        path: 'grand-dancers',
        component: () => import('pages/entertainment/GrandDancersPage.vue'),
      },
      {
        path: 'djs',
        component: () => import('pages/entertainment/DjsPage.vue'),
      },
      {
        path: 'hosts',
        component: () => import('pages/entertainment/HostsPage.vue'),
      },
      {
        path: 'hoop-troop',
        component: () => import('pages/entertainment/HoopTroopPage.vue'),
      },
      {
        path: 'young-bucks',
        component: () => import('pages/entertainment/YoungBucksPage.vue'),
      },
      {
        path: 'bucks-beats',
        component: () => import('pages/entertainment/BucksBeatsPage.vue'),
      },
      {
        path: 'rim-rockers',
        component: () => import('pages/entertainment/RimRockersPage.vue'),
      },
      {
        path: 'bango',
        component: () => import('pages/entertainment/BangoPage.vue'),
      },
    ],
  },
  {
    path: '/history',
    component: () => import('layouts/HistoryLayout.vue'),
    children: [
      { path: '', component: () => import('pages/history/HistoryPage.vue') },
      {
        path: 'famous-firsts',
        component: () => import('pages/history/FamousFirstsPage.vue'),
      },
      {
        path: 'retired-numbers',
        component: () => import('pages/history/RetiredNumbersPage.vue'),
      },
      {
        path: 'jersey-numbers',
        component: () => import('pages/history/JerseyNumbersPage.vue'),
      },
      {
        path: 'logo-nickname',
        component: () => import('pages/history/LogoNicknamePage.vue'),
      },
      {
        path: 'draft-history',
        component: () => import('pages/history/DraftHistoryPage.vue'),
      },
      {
        path: 'significant-transactions',
        component: () =>
          import('pages/history/SignificantTransactionsPage.vue'),
      },
      {
        path: 'season-recaps',
        component: () => import('pages/history/SeasonRecapsPage.vue'),
      },
    ],
  },
  {
    path: '/fans',
    component: () => import('layouts/BoxedLayout.vue'),
    children: [
      {
        path: 'deal-season',
        component: () => import('pages/fans/DealSeasonPage.vue'),
      },
      {
        path: 'bangos-meal-deal',
        component: () => import('pages/fans/BangosMealDealPage.vue'),
      },
      {
        path: 'ballers',
        component: () => import('pages/fans/BallersChampagnePage.vue'),
      },
      {
        path: 'casamigos',
        component: () => import('pages/fans/CasamigosPage.vue'),
      },
      {
        path: 'legalzoom',
        component: () => import('pages/fans/LegalZoomPage.vue'),
      },
      {
        path: 'seat-tag',
        component: () => import('pages/fans/SeatTagPage.vue'),
      },
    ],
  },
  {
    path: '/features',
    component: () => import('layouts/BoxedLayout.vue'),
    children: [
      {
        path: 'bucksfit',
        component: () => import('pages/features/BucksFitPage.vue'),
      },
      {
        path: 'fanfuel',
        component: () => import('pages/features/FanFuelPage.vue'),
      },
      {
        path: 'bucks-around-the-globe',
        component: () => import('pages/features/BucksAroundTheGlobePage.vue'),
      },
    ],
  },

  {
    path: '/arena',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/arena/ArenaPage.vue') },
    ],
  },
  {
    path: '/arena/seating-maps',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/arena/SeatingMapsPage.vue') },
    ],
  },
  {
    path: '/features',
    component: () => import('layouts/BoxedLayout.vue'),
    children: [
      {
        path: 'atc-trees-for-threes',
        component: () => import('pages/features/AtcTreesForThreesPage.vue'),
      },
      // {
      //   path: 'block-out-hunger',
      //   component: () => import('pages/features/BlockOutHungerPage.vue'),
      // },
      {
        path: 'steals-for-meals',
        component: () => import('pages/features/StealsForMealsPage.vue'),
      },
      {
        path: 'tipoff-for-homes',
        component: () => import('pages/features/TipoffForHomesPage.vue'),
      },
      {
        path: 'threes-for-tees',
        component: () => import('pages/features/ThreesForTeesPage.vue'),
      },
      // {
      //   path: 'state-fair',
      //   component: () => import('pages/features/StateFairPage.vue'),
      // },
      {
        path: 'jr-reporter',
        component: () => import('pages/features/JrReporterPage.vue'),
      },
    ],
  },
  {
    path: '/features/kids-club',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/features/KidsClubPage.vue') },
    ],
  },
  {
    path: '/bars',
    component: () => import('layouts/BoxedLayout.vue'),
    children: [{ path: '', component: () => import('pages/BarsPage.vue') }],
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
